import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
};

type InitStateType = {
  // SL - Signal Line
  SL_Coords: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  setSL_Coords: React.Dispatch<
    React.SetStateAction<{
      x1: number;
      y1: number;
      x2: number;
      y2: number;
    }>
  >;
  SL_Name: string;
  setSL_Name: React.Dispatch<React.SetStateAction<string>>;
  SL_Directions: {
    left: { name: string };
    right: { name: string };
  };
  setSL_Directions: React.Dispatch<
    React.SetStateAction<{
      left: {
        name: string;
      };
      right: {
        name: string;
      };
    }>
  >;
  // EGP - event_generation_parameters
  SL_EGP: { distance_threshold_px: number };
  setSL_EGP: React.Dispatch<
    React.SetStateAction<{
      distance_threshold_px: number;
    }>
  >;
  SL_MarkOutDirection: "left" | "right" | null;
  setSL_MarkOutDirection: React.Dispatch<
    React.SetStateAction<"left" | "right" | null>
  >;
  wrapperParams: { width: number; height: number };
  setWrapperParams: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >;
  formDistanceThreshold: number;
  setFormDistanceThreshold: React.Dispatch<React.SetStateAction<number>>;
  Sl_Dt_OutOfBoundary: boolean;
  setSl_Dt_OutOfBoundary: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialState: InitStateType = {
  SL_Coords: { x1: 0, y1: 0, x2: 0, y2: 0 },
  setSL_Coords: () => {},
  SL_Name: "Default Name",
  setSL_Name: () => {},
  SL_Directions: {
    left: { name: "Лево" },
    right: { name: "Право" },
  },
  setSL_Directions: () => {},
  SL_EGP: { distance_threshold_px: 100 },
  setSL_EGP: () => {},
  SL_MarkOutDirection: null,
  setSL_MarkOutDirection: () => {},
  wrapperParams: { width: 0, height: 0 },
  setWrapperParams: () => {},
  formDistanceThreshold: 0,
  setFormDistanceThreshold: () => {},
  Sl_Dt_OutOfBoundary: false,
  setSl_Dt_OutOfBoundary: () => {},
};

const EditContext = React.createContext(initialState);

function EditProvider({ children }: Props) {
  const [SL_Coords, setSL_Coords] = useState(initialState.SL_Coords);
  const [SL_Name, setSL_Name] = useState(initialState.SL_Name);
  const [SL_Directions, setSL_Directions] = useState(
    initialState.SL_Directions
  );
  const [SL_EGP, setSL_EGP] = useState(initialState.SL_EGP);
  const [formDistanceThreshold, setFormDistanceThreshold] = useState(
    initialState.SL_EGP.distance_threshold_px
  );
  const [Sl_Dt_OutOfBoundary, setSl_Dt_OutOfBoundary] = useState(
    initialState.Sl_Dt_OutOfBoundary
  );
  const [SL_MarkOutDirection, setSL_MarkOutDirection] = useState(
    initialState.SL_MarkOutDirection
  );

  const [wrapperParams, setWrapperParams] = useState({ width: 0, height: 0 });

  return (
    <EditContext.Provider
      value={{
        SL_Coords,
        setSL_Coords,
        SL_Name,
        setSL_Name,
        SL_Directions,
        setSL_Directions,
        SL_EGP,
        setSL_EGP,
        SL_MarkOutDirection,
        setSL_MarkOutDirection,
        wrapperParams,
        setWrapperParams,
        formDistanceThreshold,
        setFormDistanceThreshold,
        Sl_Dt_OutOfBoundary,
        setSl_Dt_OutOfBoundary,
      }}
    >
      {children}
    </EditContext.Provider>
  );
}

function useEditProvider() {
  const context = React.useContext(EditContext);
  if (context === undefined) {
    throw new Error("useEditProvider must be used within a EditProvider");
  }
  return context;
}

export { EditProvider, useEditProvider };
